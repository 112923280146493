import { useState } from 'react'

import ModelModal from '../ModelModal'
import TriagemStep2 from './TriagemStep2'

interface ContentModalProps {
  title: string
  description: string
}

export default function YouUseIntegration() {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [height, setHeight] = useState<any>(0)
  const [messageErrorReport, setMessageErrorReport] = useState<string>('')
  const [messageError, setMessageError] = useState<string>('')
  const [contentModal, setContentModal] = useState<ContentModalProps>({
    title: '',
    description: '',
  })

  function handleClick() {
    if (!height && contentModal.title) {
      setMessageError('É necessário  possuir uma plataforma integradora')

      return
    }

    if (!height) {
      setMessageError(
        'Por favor, selecione uma das opções abaixo para continuar.'
      )

      return
    }

    setHeight('auto')
  }

  return (
    <>
      <section className="containerForm">
        <h2 className="containerTitleForm">
          Você utiliza alguma plataforma integradora?
        </h2>
        <button
          type="button"
          className="whatIntegration"
          onClick={() => {
            setContentModal({
              title: 'O que é uma plataforma integradora?',
              description: `Um integrador serve para auxiliar o controle da sua loja e automatizar todos os processos de venda online em diversos marketplaces. 
          É possível gerenciar seus pedidos, estoque e todos os seus produtos nos diversos canais em um único local.
          `,
            })
            setShowModal(true)
          }}
        >
          O que é uma plataforma integradora?
        </button>
        {messageError && <p className="erros">{messageError}</p>}
        <label
          htmlFor="integrationTrue"
          className={`${
            messageErrorReport ? 'errorReport' : ''
          } containerIntegration`}
        >
          Sim, já utilizo uma integradora.
          <input
            type="radio"
            id="integrationTrue"
            name="integradora"
            onChange={() => {
              setHeight(height === 0 ? 'auto' : 0)
              setMessageError('')
            }}
            checked={height && true}
            className="IntegrationInput"
            value="true"
          />
        </label>
        <div
          id="example-panel"
          className={`${height !== 0 ? 'triagem-open' : 'triagem-closed'}`}
        >
          <div className="triagem-wrapper">
            <TriagemStep2 setMessageErrorReport={setMessageErrorReport} />
          </div>
        </div>
        {!height && (
          <label htmlFor="integrationFalse" className="containerIntegration">
            Não utilizo uma integradora.
            <input
              type="radio"
              id="integrationFalse"
              name="integradora"
              onChange={() => {
                setContentModal({
                  title: 'Cadastro não realizado!',
                  description: `Infelizmente, é necessário possuir uma plataforma integradora para seguir com o preenchimento do cadastro.`,
                })
                setShowModal(true)
              }}
              className="IntegrationInput"
              value="false"
            />
          </label>
        )}
      </section>
      {!height && (
        <button
          type="submit"
          className="buttonSubmitForm"
          onClick={() => handleClick()}
        >
          CONTINUAR
        </button>
      )}
      {showModal ? (
        <ModelModal
          title={contentModal?.title}
          description={contentModal?.description}
          setShowModal={setShowModal}
        />
      ) : null}
    </>
  )
}
