import { useState, useCallback } from 'react'
import { setStorage } from 'src/utils/storage'

import ModelModal from '../ModelModal'
import data from './data.json'

interface IntegrationOptionsProps {
  id: number
  integrationName: string
}

interface TriagemStep2Props {
  setMessageErrorReport: React.Dispatch<React.SetStateAction<string>>
}

export const contentModal = {
  title: 'O que é uma plataforma integradora?',
  description: `Um integrador serve para auxiliar o controle da sua loja e automatizar todos os processos de venda online em diversos marketplaces. 
          É possível gerenciar seus pedidos, estoque e todos os seus produtos nos diversos canais em um único local.
          `,
}

export default function TriagemStep2({
  setMessageErrorReport,
}: TriagemStep2Props) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showAnotherOption, setShowAnotherOption] = useState<boolean>(false)
  const [anotherOptionText, setAnotherOptionText] = useState<string>('')
  const [messageError, setMessageError] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<string>('')

  const handleOptionChange = useCallback(
    (value: string) => {
      setSelectedOption(value)
      if (value !== 'Outra Plataforma') {
        setShowAnotherOption(false)
        setMessageError('')
        setMessageErrorReport('')
      } else {
        setShowAnotherOption(true)
      }
    },
    [setMessageErrorReport]
  )

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAnotherOptionText(event.target.value)
    },
    []
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      if (!selectedOption) {
        setMessageError(
          'Por favor, selecione uma das opções abaixo para continuar.'
        )
        setMessageErrorReport(
          'Por favor, selecione uma das opções abaixo para continuar.'
        )
      } else if (selectedOption === 'Outra Plataforma' && !anotherOptionText) {
        setMessageError(
          'Por favor, Insira o nome da sua integradora para continuar'
        )
        setMessageErrorReport(
          'Por favor, selecione uma das opções abaixo para continuar.'
        )
      } else {
        const integradora =
          selectedOption === 'Outra Plataforma'
            ? anotherOptionText
            : selectedOption

        setStorage('integradora', integradora)
        window.location.href = '/marketplace/cadastro'
      }
    },
    [selectedOption, anotherOptionText, setMessageErrorReport]
  )

  function renderIntegrationOptions(
    { integrationName }: IntegrationOptionsProps,
    index: number
  ) {
    return (
      <label
        htmlFor={`integration${index}`}
        className="containerIntegration"
        key={index}
      >
        {integrationName}
        <input
          type="radio"
          id={`integration${index}`}
          name="integradora"
          className="IntegrationInput"
          value={integrationName}
          onChange={() => handleOptionChange(integrationName)}
        />
      </label>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="containerSubForm">
        <section>
          {messageError ? <p className="erros">{messageError}</p> : null}
          <section className="containerRenderIntegrations">
            {data.map(renderIntegrationOptions)}
            {showAnotherOption ? (
              <input
                type="text"
                className="IntegrationInput inputText"
                placeholder="Descreva aqui o nome da sua integradora"
                onChange={handleInputChange}
                value={anotherOptionText}
              />
            ) : null}
          </section>
        </section>
        <button type="submit" className="buttonSubmitForm">
          CONTINUAR
        </button>
      </form>
      {showModal ? (
        <ModelModal
          title={contentModal?.title}
          description={contentModal?.description}
          setShowModal={setShowModal}
        />
      ) : null}
    </>
  )
}
